export const enTranslate = {
  enAbout: 'A video chat with a 2D map, where you will hear others\' voices based on the location.'
    + ' It holds multiple conversations in a venue parallelly, and you may walk around between them.'
    + ' Sharing screens, cameras, texts, images, YouTube, and documents on google drive are supported.'
    + ' You may drop or paste on the map.',
  enTopPageUrl: 'https://scrapbox.io/binaural-meet/Top_page',
  enMoreInfo: '... more info',
  YourName: 'MY NAME',
  Venue: 'MEETING SPACE',
  EnterTheVenue: 'Enter the venue',
  Contents: 'Contents',
  'Create and Share': 'Create and Share',
  'Share Text':'Share Text',
  'Share iframe':'Share web page (Can\'t display many sites)',
  'Share image':'Share image',
  'Share zone image':'Share zone image',
  'Select video camera to share':'Select video camera to share',
  'Meeting Space':'Meeting Space',
  shareImport: '_Load...',
  shareImportTip: 'Load the shared content from the downloaded .json file.',
  shareDownload: '_Save Meeting Space',
  shareDownloadTip: 'Download all shared contents except screen sharing and cameras to a file.',
  shareIframe: 'I_frame',
  shareIframeTip: 'Web pages that can be displayed are limited to those that can be displayed in an iframe.',
  shareText: '_Text (can paste directly)',
  sharePaste: 'Paste from clipboard (_v)',
  sharePasteTip: 'Ctrl+V / Cmd+V shortcut directly works on the map. URL to PDF, images, YouTube, Google Docs',
  shareImage: 'Ima_ge (can paste directly)',
  shareZoneImage: '_Chat Zone',
  shareImageTip: 'Image files will be uploaded to gyazo.com. You can also paste the URL of the image.',
  shareGDrive: 'Import Files from Google Drive',
  shareGDriveTip: 'Upload any available file from Google Drive',
  shareWhiteboard: '_Whiteboard',
  shareWhiteboardTip: 'Place the wbo.ophir.dev whiteboard.',
  shareEditEnd: 'Stop editing',
  shareCamera: '_Camera',
  shareScreenBackground:'Screen as the _background',
  stopScreenBackground:'Stop _background screen',
  shareScreenContent:'_Screen',
  shareScreenContentTip:'Reducing the screen update frequency (fps) will reduce the network load.',
  stopScreen:'C_lose all screen and camera windows',
  frameRateSetting: 'Frame rate:',
  fps: 'fps',
  shareMouse:'_Mouse cursor',
  shareMouseTip:'Show the mouse cursor to other participants. The avatar also always gazes at the mouse cursor.',
  stopMouse: 'Stop sharing _Mouse cursor',
  recorderMenuTitle: '_Record and playback',
  recorderStart: 'Start _recording',
  recorderStop: 'Stop _recording',
  playerStop: 'Stop _playback',
  playerPlayfile: '_Load and play',

  ttCreateAndshare: '_Create and share',
  ttMicMute: '_Mic mute',
  ttPlayAndRec: '_Record and Playback',
  headphoneL1Chrome: 'Stereo headphone (Caution: no echo cancel)',
  headphoneL1: 'Stereo headphone',
  headphoneL2: 'Monaural speaker',
  etConnection: 'No connection',
  emConnection: 'Please check internet connection. Earshot Chat uses https. If internet is OK, the server may have problems.',
  etMicPermission: 'No permission to use microphone',
  emMicPermission: 'Your browser does not permit to use microphone. Please give the permission and reload your browser.',
  etNoMic: 'No microphone',
  emNoMic: 'Please check if your PC has a microphone. Earshot Chat requires it.',
  emClose: 'Close',
  emNeverShow: 'Never show this in this session',
  etNoChannel: 'No data channel',
  emNoChannel: 'Please check firewall setting. Earshot Chat connect to https (port 443/TCP) and port 8801-8810/UDP or 80/TCP.',

  etDataConnection: 'Data connection failed',
  etRtcConnection: 'Media connection failed',
  emDataConnection: 'Please check firewall setting. Earshot Chat connect to https (port 443/TCP).',
  emRtcConnection: 'Please check firewall setting. Earshot Chat connect to https (port 443/TCP).',

  ctTitle: 'Show Title',
  ctUnTitle: 'Hide Title',

  etRetry: 'Please reload',
  emRetry: 'The connection had been cut off once. Please click the reload button of the browser.',
  imageDropzoneText:'Drag and drop images here or click',
  lsTitle:'Avatar and notification setting',
  lsName: 'Name',
  lsColor: 'Color',
  lsColorAvatar:'Avatar',
  lsColorText:'Text',
  lsAutoColor:'Auto',
  lsImage: 'Avatar image',
  lsEmail: 'Input email to use Gravatar\'s image',
  lsImageFile: 'Upload file to use image file',
  btSave: 'Save and close',
  btCancel: 'Cancel change',
  lsNotifyCall: 'Called',
  lsNotifyTouch: 'Touched',
  lsNotifyNear: 'Approached',
  lsNotifyYarn: 'Yarn phone',
  rsCall: 'Call',
  lsNotification: 'Notification',
  rsConnectYarnPhone: 'Connect yarn phone',
  rsCutYarnPhone: 'Disconnect yarn phone',
  rsChatTo: 'Send chat only to {{name}}',
  ctPin: 'Lock position and size',
  ctUnpin: 'Unlock position and size',
  ctEdit: 'Start editing (No view sync)',
  ctEndEdit: 'End editing (Sync view)',
  ctUploadZone: 'Upload and share',
  ctEditIframe: 'Browse page (No sync)',
  ctEndEditIframe: 'End browse (No sync)',
  ctEditText: 'Edit (No scroll sync)',
  ctEndEditText: 'End edit (Sync scroll)',
  ctEditWhiteboard: 'Edit (No scroll sync)',
  ctEndEditWhiteboard: 'End edit (No scroll sync)',
  ctEditGDrive: 'Edit/Update (No scroll sync)',
  ctEndEditGDrive: 'End edit (No auto-update)',
  ctEndEditYoutube: 'Sync play pos',
  ctEditYoutube: 'No sync',
  ctMoveTop: 'Move to the top',
  ctMoveBottom: 'Move to the bottom',
  ctWallpaper: 'Make this wallpaper',
  ctUnWallpaper: 'Remove this from wallpaper',
  ctCopyToClipboard: 'Copy to clipboard',
  ctMaximize: 'Zoom and move to maximize this content',
  ctUnMaximize: 'Unmaximize and restore the view',
  ctDelete: 'Delete',
  ctClose: 'Close',
  ctName: 'Titile',
  ctStreamingMedia: 'Content URL',
  ctFocus: 'Show it in the center',
  ctFrameVisible: 'Normal frame',
  ctFrameInvisible: 'Invisible frame',
  ctOpenAudioZone: 'Meeting space',
  ctClosedAudioZone: 'Sound proof',
  ctNotAudioZone: 'Just an image',
  ctOpaque: 'Opaque',
  ctTransparent: 'Transparent',
  ctGenerateAvatar: 'Customize Avatar',
  deleteMsg: 'Remove',
  upload: 'Upload',
  Save: 'Save',
  Clear: 'Clear',
  Cancel: 'Cancel',
  broadcastMyVoice: 'Broadcast my voice',
  showTrackedFace: 'Show only facial feature points',
  trackWholeBody: 'Track whole body',
  soundLocalizationBasedOn: 'Sound localization based on',
  stereoNote: 'Are you really using headphones?',
  stereoNoteDesc: 'If you use it with a speaker, it will echo back the other party\'s voice, making it very difficult for the other party to speak.',
  stereoConfirmed: 'Confirmed and change into headphone mode',
  stereoCancel: 'Keep loudspeaker mode',
  slAvatar: 'Avatar (avatar\'s direction)',
  slUser: 'User (top is front)',
  usage: 'How to use',
  cmJoined: '{{name}} joined.',
  cmLeft: '{{name}} Left.',
  cmNameChanged: '{{old}} changed its name to {{new}}.',
  cmCallBy: '{{name}} called you.',
  cmCallTo: 'You called {{name}}.',
  cmToAll: 'To all',
  cmToName: 'To {{name}}',
  cmSend: 'Send (CTRL+Enter)',
  cmPrivateFrom: 'Private from {{name}}',
  cmPrivateTo: 'Private to {{name}}',
  noCalled: '{{name}} called you.',
  noTouched: '{{name}} touched you.',
  noNear: '{{name}} approached you.',
  noYarn: '{{name}} connected yarn.',
  afkTitle: 'In away from keyboard mode (L / Esc)',
  afkMessage: 'Hit any key or click mouse to return.',
  gdFailed: 'The file in Google Drive could not be accessed.\r\nChange the file-sharing settings so that everyone on the Internet can see it.',
  videoLimit: 'Limit on the number of video streams',
  audioLimit: 'Limit on the number of audio streams',
  connectionStatus: 'Connection Status',
  deleteImageMsg: 'Remove Image?',
  deleteZoneMsg: 'Remove Chat Zone?',
  deleteTextMsg: 'Remove Text?',
  deleteYes: 'Remove',
  ctKick: 'Kick user from meeting',
  deleteNo: "Don't Remove",
  copyClipboard: 'Meeting address copied to clipboard',
  deleteAlert: 'ALERT',
  ctProximity: 'Enable Chat Zone',
  ctUnProximity: 'Disable Chat Zone',
  ctStopWatchOn: 'Start zone stopwatch',
  ctStopWatchOff: 'Stop zone stopwatch',
  ctScaleRotate: 'Enable Scale and Rotate',
  ctUnScaleRotate: 'Disable Scale and Rotate',
  settingPreference: '_Preferences...',
  meetingSpace: '_Meeting Space',
  meetingDesc: 'Meeting Description',
  broadcastMyVideo: 'Broadcast my video',
  broadcastMySpeakVideo: 'Speak & Show',
  stereoswitch: 'Stereo sound (via headset)',
  speakerSetting: 'Change the browser default setting to access the mic and speaker',
  permissionTitle: 'Please grant Earshot access to your camera and microphone so that other participants can see and hear you.',
  welcomePermission: 'Hi! Welcome to Earshot',
  reqPermission: 'REQUEST ACCESS',
}
export type EnKeyList = keyof typeof enTranslate
